import {
  ADD_TREATMENT_SEO_DATA_ADMIN,
  ASSIGN_TREATMENTS_TO_CALENDAR,
  CREATE_TREATMENT_ADMIN,
  DELETE_TREATMENT_ADMIN,
  GET_TREATMENT_DOCTOR,
  GET_TREATMENT_SEO_DATA_ADMIN,
  HIDE_TREATMENT_DOCTOR,
  LIST_TREATMENTS_ADMIN,
  LIST_TREATMENTS_ADMIN_PAGINATED,
  LIST_TREATMENTS_DOCTOR,
  LIST_TREATMENTS_DOCTOR_PAGINATED,
  LIST_TREATMENTS_SEO_ADMIN,
  LIST_TREATMENTS_TO_ADD_DOCTOR,
  REMOVE_TREATMENT_SEO_DATA_ADMIN,
  UPDATE_TREATMENT_ADMIN,
  UPDATE_TREATMENT_DOCTOR,
  UPDATE_TREATMENT_SEO_DATA_ADMIN
} from './graphql/queries';
import { useQuery } from '../useQuery';
import { useMutation } from '../useMutation';

/** @format */
export const useTreatments = () => {
  const { getDataGQL: listTreatmentsAdmin } = useQuery(LIST_TREATMENTS_ADMIN);
  const { getDataGQL: listTreatmentsAdminPaginated } = useQuery(LIST_TREATMENTS_ADMIN_PAGINATED);
  const { getDataGQL: listTreatmentsDoctor } = useQuery(LIST_TREATMENTS_DOCTOR);
  const { getDataGQL: getTreatmentDoctor } = useQuery(GET_TREATMENT_DOCTOR);
  const { getDataGQL: listTreatmentsDoctorPaginated } = useQuery(LIST_TREATMENTS_DOCTOR_PAGINATED);
  const { getDataGQL: listTreatmentsToAddDoctor } = useQuery(LIST_TREATMENTS_TO_ADD_DOCTOR);
  const { getDataGQL: createTreatmentAdmin } = useMutation(CREATE_TREATMENT_ADMIN);
  const { getDataGQL: updateTreatmentAdmin } = useMutation(UPDATE_TREATMENT_ADMIN);
  const { getDataGQL: deleteTreatmentAdmin } = useMutation(DELETE_TREATMENT_ADMIN);
  const { getDataGQL: updateTreatmentDoctor } = useMutation(UPDATE_TREATMENT_DOCTOR);
  const { getDataGQL: hideTreatmentDoctor } = useMutation(HIDE_TREATMENT_DOCTOR);
  const { getDataGQL: assignTreatmentsToCalendar } = useMutation(ASSIGN_TREATMENTS_TO_CALENDAR);
  const { getDataGQL: getTreatmentSeoDataAdmin } = useMutation(GET_TREATMENT_SEO_DATA_ADMIN);
  const { getDataGQL: listTreatmentsSeoAdmin } = useMutation(LIST_TREATMENTS_SEO_ADMIN);
  const { getDataGQL: updateTreatmentSeoDataAdmin } = useMutation(UPDATE_TREATMENT_SEO_DATA_ADMIN);
  const { getDataGQL: addTreatmentSeoDataAdmin } = useMutation(ADD_TREATMENT_SEO_DATA_ADMIN);
  const { getDataGQL: removeTreatmentSeoDataAdmin } = useMutation(REMOVE_TREATMENT_SEO_DATA_ADMIN);

  const onListTreatmentsAdmin = async () => {
    const response = await listTreatmentsAdmin();
    if (response?.adminRoute?.listTreatmentsAdmin) {
      return response?.adminRoute?.listTreatmentsAdmin;
    }
    return null;
  };

  const onListTreatmentsAdminPaginated = async ({ filters }) => {
    const response = await listTreatmentsAdminPaginated({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listTreatmentsAdminPaginated) {
      return response?.adminRoute?.listTreatmentsAdminPaginated;
    }
    return null;
  };

  const onListTreatmentsDoctor = async () => {
    const response = await listTreatmentsDoctor();
    if (response?.adminRoute?.listTreatmentsDoctor) {
      return response?.adminRoute?.listTreatmentsDoctor;
    }
    return null;
  };

  const onGetTreatmentDoctor = async ({ treatment_id }) => {
    const response = await getTreatmentDoctor({
      variables: {
        treatment_id
      }
    });
    if (response?.adminRoute?.getTreatmentDoctor) {
      return response?.adminRoute?.getTreatmentDoctor;
    }
    return null;
  };

  const onListTreatmentsDoctorPaginated = async ({ filters }) => {
    const response = await listTreatmentsDoctorPaginated({
      variables: {
        filters
      }
    });
    if (response?.adminRoute?.listTreatmentsDoctorPaginated) {
      return response?.adminRoute?.listTreatmentsDoctorPaginated;
    }
    return null;
  };

  const onListTreatmentsToAddDoctor = async () => {
    const response = await listTreatmentsToAddDoctor();
    if (response?.adminRoute?.listTreatmentsToAddDoctor) {
      return response?.adminRoute?.listTreatmentsToAddDoctor;
    }
    return null;
  };

  const onCreateTreatmentAdmin = async ({ treatment_data }) => {
    const response = await createTreatmentAdmin({
      variables: {
        treatment_data
      }
    });
    if (response?.adminRoute?.createTreatmentAdmin) {
      return response?.adminRoute?.createTreatmentAdmin;
    }
    return null;
  };

  const onUpdateTreatmentAdmin = async ({ treatment_id, treatment_data }) => {
    const response = await updateTreatmentAdmin({
      variables: {
        treatment_id,
        treatment_data
      }
    });
    if (response?.adminRoute?.updateTreatmentAdmin) {
      return response?.adminRoute?.updateTreatmentAdmin;
    }
    return null;
  };

  const onDeleteTreatmentAdmin = async ({ treatment_id }) => {
    const response = await deleteTreatmentAdmin({
      variables: {
        treatment_id
      }
    });
    if (response?.adminRoute?.deleteTreatmentAdmin) {
      return response?.adminRoute?.deleteTreatmentAdmin;
    }
    return null;
  };

  const onUpdateTreatmentDoctor = async ({ treatment_id, treatment_data }) => {
    const response = await updateTreatmentDoctor({
      variables: {
        treatment_id,
        treatment_data
      }
    });
    if (response?.adminRoute?.updateTreatmentDoctor) {
      return response?.adminRoute?.updateTreatmentDoctor;
    }
    return null;
  };

  const onHideTreatmentDoctor = async ({ treatment_id }) => {
    const response = await hideTreatmentDoctor({
      variables: {
        treatment_id
      }
    });
    if (response?.adminRoute?.hideTreatmentDoctor) {
      return response?.adminRoute?.hideTreatmentDoctor;
    }
    return null;
  };

  const onAssignTreatmentsToCalendar = async ({ treatments, calendar_id }) => {
    const response = await assignTreatmentsToCalendar({
      variables: {
        treatments,
        calendar_id
      }
    });
    if (response?.adminRoute?.assignTreatmentsToCalendar) {
      return response?.adminRoute?.assignTreatmentsToCalendar;
    }
    return null;
  };

  const onGetTreatmentSeoDataAdmin = async (data) => {
    const response = await getTreatmentSeoDataAdmin({
      variables: data
    });
    if (response?.adminRoute?.getTreatmentSeoDataAdmin) {
      return response?.adminRoute?.getTreatmentSeoDataAdmin;
    }
    return null;
  }

  const onListTreatmentsSeoAdmin = async (data) => {
    const response = await listTreatmentsSeoAdmin({
      variables: data
    });
    if (response?.adminRoute?.listTreatmentsSeoAdmin) {
      return response?.adminRoute?.listTreatmentsSeoAdmin;
    }
    return null;
  }

  const onUpdateTreatmentSeoDataAdmin = async (data) => {
    const response = await updateTreatmentSeoDataAdmin({
      variables: data
    });
    if (response?.adminRoute?.updateTreatmentSeoDataAdmin) {
      return response?.adminRoute?.updateTreatmentSeoDataAdmin;
    }
    return null;
  }

  const onAddTreatmentSeoDataAdmin = async (data) => {
    const response = await addTreatmentSeoDataAdmin({
      variables: data
    });
    if (response?.adminRoute?.addTreatmentSeoDataAdmin) {
      return response?.adminRoute?.addTreatmentSeoDataAdmin;
    }
    return null;
  }

  const onRemoveTreatmentSeoDataAdmin = async (data) => {
    const response = await removeTreatmentSeoDataAdmin({
      variables: data
    });
    if (response?.adminRoute?.removeTreatmentSeoDataAdmin) {
      return response?.adminRoute?.removeTreatmentSeoDataAdmin;
    }
    return null;
  };

  return {
    onListTreatmentsAdmin,
    onListTreatmentsAdminPaginated,
    onListTreatmentsDoctor,
    onGetTreatmentDoctor,
    onListTreatmentsDoctorPaginated,
    onListTreatmentsToAddDoctor,
    onCreateTreatmentAdmin,
    onUpdateTreatmentAdmin,
    onDeleteTreatmentAdmin,
    onUpdateTreatmentDoctor,
    onHideTreatmentDoctor,
    onAssignTreatmentsToCalendar,
    onGetTreatmentSeoDataAdmin,
    onListTreatmentsSeoAdmin,
    onUpdateTreatmentSeoDataAdmin,
    onAddTreatmentSeoDataAdmin,
    onRemoveTreatmentSeoDataAdmin
  };
};
