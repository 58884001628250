import { Box, Image } from '@chakra-ui/react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material';
import { IconAt, IconBuilding, IconBulb, IconCheck, IconMapPin, IconPhone, IconPhoto, IconSignature, IconX } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import DropzoneFormikInput from '../../utilities/Dropzone';
import { v4 as uuidv4 } from 'uuid';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { toast } from 'react-toastify';

const ModalOfficeAdmin = ({ modalOpen, handleModalClose, cities, office, setEditMode }) => {
  const [loadingImage, setLoadingImage] = React.useState(false);
  const [uuid, setUuid] = React.useState(uuidv4());

  const { onUpdateOfficeAdmin, onCreateOfficeAdmin } = useOffices();

  useEffect(() => {
    setUuid(uuidv4());
  }, [office, modalOpen]);

  const handleSubmit = async (values) => {
    const { name, image, address, phone, email, city_id, active } = values;

    if (office?.id) {
      onUpdateOfficeAdmin({
        office_id: office.id,
        office_data: {
          name,
          image,
          address,
          phone,
          email,
          city_id,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    } else {
      onCreateOfficeAdmin({
        office_data: {
          uuid,
          name,
          image,
          address,
          phone,
          email,
          city_id,
          active
        }
      }).then((res) => {
        if (res?.response) {
          toast(res?.response, {
            style: {
              fontSize: '14px',
              backgroundColor: '#00e676',
              color: '#ffffff'
            }
          });
          handleModalClose();
        }
      });
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      allowFullScreen={true}
      className={'modal'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <>
          <Formik
            initialValues={{
              name: office?.name ?? '',
              image: office?.image ?? '',
              address: office?.address ?? '',
              phone: office?.phone ?? '',
              email: office?.email ?? '',
              city_id: office?.city_id ?? '',
              active: office?.active ?? false
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required('Nome richiesto'),
              image: Yup.string().max(255).required('Immagine richiesta'),
              address: Yup.string().max(255).required('Indirizzo richiesto'),
              phone: Yup.string().max(255).nullable(),
              email: Yup.string().max(255).nullable(),
              city_id: Yup.number().required('Città richiesta'),
              active: Yup.boolean().nullable()
            })}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
            enableReinitialize={true}
          >
            {({ errors, handleBlur, handleSubmit, touched, values, setFieldValue }) => (
              <form onSubmit={handleSubmit} id={'appointment-form'}>
                <Box
                  w={'100vw'}
                  h={'100vh'}
                  bg={'transparent'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  pointerEvents={'none'}
                >
                  <Box
                    className={'container'}
                    w={'85%'}
                    h={'70%'}
                    bg={'white'}
                    borderRadius={'20px'}
                    pointerEvents={'all'}
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <Box className={'top_data'} h={'calc(100% - 50px)'} overflow={'auto'}>
                      <Box
                        position={'absolute'}
                        top={'2%'}
                        right={'2%'}
                        w={'20px'}
                        h={'20px'}
                        bg={'lightgrey'}
                        borderRadius={'20px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={handleModalClose}
                      >
                        <Typography>X</Typography>
                      </Box>

                      <Typography id="modal-modal-title" variant="h4" mt={4} ml={1.5}>
                        Dati Ufficio
                      </Typography>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconSignature stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                            <InputLabel htmlFor="input-name">Nome</InputLabel>
                            <OutlinedInput
                              id="input-name"
                              type="text"
                              value={values.name}
                              name="name"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('name', event.target.value)}
                              label="Nome"
                              inputProps={{
                                maxLength: 45
                              }}
                            />
                            {touched.name && errors.name && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhoto stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'85%'}>
                          <FormControl fullWidth error={Boolean(touched.image && errors.image)} w={'100%'}>
                            {loadingImage && <>Loading</>}
                            <Typography id="modal-modal-description" variant="body1" fontWeight={'bold'} mb={1} ml={1}>
                              Immagine
                            </Typography>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                              <Image src={values.image} w={'100px'} h={'100px'} objectFit={'cover'} />
                              <DropzoneFormikInput
                                variant={'office_image'}
                                setFieldValue={setFieldValue}
                                field={'image'}
                                setLoading={setLoadingImage}
                                folder={'offices'}
                                label={'Carica'}
                                extra_data={{
                                  office_uuid: office?.uuid ?? uuid
                                }}
                              />
                            </Box>
                            {touched.image && errors.image && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.image}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>

                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconMapPin stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.address && errors.address)}>
                            <InputLabel htmlFor="input-address">Indirizzo</InputLabel>
                            <OutlinedInput
                              id="input-address"
                              type="text"
                              value={values.address}
                              name="address"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('address', event.target.value)}
                              label="Indirizzo"
                            />
                            {touched.address && errors.address && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.address}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconPhone stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.phone && errors.phone)}>
                            <InputLabel htmlFor="input-phone">Telefono</InputLabel>
                            <OutlinedInput
                              id="input-phone"
                              type="text"
                              value={values.phone}
                              name="phone"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('phone', event.target.value)}
                              label="Telefono"
                            />
                            {touched.phone && errors.phone && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.phone}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconAt stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                            <InputLabel htmlFor="input-surname">Email</InputLabel>
                            <OutlinedInput
                              id="input-email"
                              type="text"
                              value={values.email}
                              name="email"
                              onBlur={handleBlur}
                              onChange={() => setFieldValue('email', event.target.value)}
                              label="Email"
                            />
                            {touched.email && errors.email && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.email}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBuilding stroke={1.5} size="1.3rem" />
                        <Box ml={10} w={'100%'}>
                          <FormControl fullWidth error={Boolean(touched.city_id && errors.city_id)}>
                            <Autocomplete
                              id="demo-simple-select"
                              labelId="city_id-label"
                              fullWidth={true}
                              name={'city_id'}
                              defaultValue={() => {
                                let city = cities.find((city) => city.id === values.city_id);

                                if (!city) {
                                  return '';
                                }

                                return {
                                  id: Number(city?.id),
                                  label: city?.name
                                };
                              }}
                              options={cities?.map((city) => {
                                return {
                                  id: Number(city?.id),
                                  label: city?.name
                                };
                              })}
                              onChange={(e, data) => {
                                setFieldValue('city_id', data?.id ?? '');
                              }}
                              onBlur={handleBlur}
                              renderInput={(params) => <TextField {...params} label="Citta" />}
                            />
                            {touched.city_id && errors.city_id && (
                              <FormHelperText error id="standard-weight-helper-text-password-login">
                                {errors.city_id}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      <Box display={'flex'} mt={5} bg={'rgba(200,200,200,0.2)'} p={'10px'} alignItems={'center'}>
                        <IconBulb stroke={1.5} size="1.3rem" />
                        <Box ml={10}>
                          <FormControl fullWidth error={Boolean(touched.active && errors.active)}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values?.active}
                                  onChange={(event) => setFieldValue('active', event.target.checked)}
                                  name="checked"
                                  color="primary"
                                />
                              }
                              label={<Typography variant="subtitle1">Attivo</Typography>}
                            />
                            {touched.active && errors.active && (
                              <FormHelperText error id="standard-weight-helper-text-email-login">
                                {errors.active}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Box>
                      {errors.general && (
                        <FormHelperText
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '10px',
                            fontSize: '16px',
                            color: '#f44336'
                          }}
                          error
                          id="standard-weight-helper-text-password-login"
                        >
                          {errors.general}
                        </FormHelperText>
                      )}
                    </Box>
                    <Divider />
                    <Box
                      className={'bottom_buttons'}
                      h={'50px'}
                      position={'absolute'}
                      bottom={0}
                      w={'100%'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      px={20}
                    >
                      <Button variant={'contained'} color={'error'}>
                        <IconX stroke={1.5} size="1rem" onClick={() => setEditMode(false)} />
                      </Button>
                      <Button variant={'contained'} color={'success'} type={'submit'}>
                        <IconCheck stroke={1.5} size="1rem" />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </Box>
    </Modal>
  );
};
export default ModalOfficeAdmin;
