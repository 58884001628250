import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';

export const UIHtmlEditor = ({ content, onChange }) => {
  const editorRef = useRef(null);

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    <Box>
      <div className="p-4">
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          apiKey={process.env.REACT_APP_TINYMCE_KEY}
          initialValue={decodeHtml(content)}
          init={{
            height: 500,
            selector: "textarea",
            plugins:  "lists advlist",
            toolbar:
              "undo redo | formatselect | bold italic removeformat | image | blocks | alignleft aligncenter alignright lineheight | backcolor forecolor | fontsize | numlist bullist | ",
          }}
          onEditorChange={(content) => {
            onChange(content);
          }}
        />
      </div>
    </Box>
  );
};

export default UIHtmlEditor;
